<template lang="pug">
    article#home
        Background
        Banner
        Experience
        //- InscrevaSe
        Galeria
</template>

<script>
export default {
    name: "view-home",
    components: {
        Background: () => import( /* webpackChunkName: "Background" */ '@sections/Home/Background/Background'),
        Banner: () => import( /* webpackChunkName: "Banner" */ '@sections/Home/Banner/Banner'),
        Experience: () => import( /* webpackChunkName: "Experience" */ '@sections/Home/Experience/Experience'),
        InscrevaSe: () => import( /* webpackChunkName: "InscrevaSe" */ '@sections/Home/InscrevaSe/InscrevaSe'),
        Galeria: () => import( /* webpackChunkName: "Galeria" */ '@sections/Home/Galeria/Galeria'),
    },
}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>